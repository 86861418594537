import { useSuspenseQuery } from "@apollo/client";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { ContentPane } from "../../components/ContentPane.tsx";
import { LongDate } from "../../components/l10n/DateFormat.tsx";
import { ContentLoadingSuspense } from "../../components/loading/Loading.tsx";
import { WModal } from "../../components/modal/WModal.tsx";
import { DetailsHolder, DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { gql } from "../graphql.gen/gql.ts";
import { adminUserLink } from "../itemLinks.ts";
import { ImpersonateModalContent } from "./ImpersonationBlock.tsx";

type UserId = string;

const USER_QUERY = gql(/* GraphQL */ `
    query GetUserDetails($id: String!) {
        admin_user_details(id: $id) {
            id
            signup_site
            name
            profile_data {
                # avatar
                created_at
                # updated_at
                email
                first_name
                last_name
                # personal_id_number
                # phone_number
            }
        }
    }
`);

const userHeader = <h3 className="font-size-subtitle pb-0">User</h3>;

export function UserDetails({ value }: { value: UserId }) {
    const { data } = useSuspenseQuery(USER_QUERY, { variables: { id: value }, fetchPolicy: "cache-first" });
    const user = data.admin_user_details;
    const created_at = user.profile_data?.created_at ? <LongDate date={user.profile_data.created_at} /> : "-";

    return (
        <>
            <div className="HStack items-center gap-2">
                {userHeader}
                <WButton label="View" variant="ghost" color="primary" size="bar" action={adminUserLink(value)} />
                <WModal button={<WModalButton label="Impersonate" size="bar" icon="jp-swap-icon" />}>
                    <ImpersonateModalContent item={user} />
                </WModal>
            </div>
            <DetailsHolder>
                <DetailsTable>
                    <DetailsTableRow title="ID:">{user.id}</DetailsTableRow>
                    <DetailsTableRow title="Name:">{user.name}</DetailsTableRow>
                    <DetailsTableRow title="Signup Site:">{user.signup_site}</DetailsTableRow>
                    <DetailsTableRow title="First Name:">{user.profile_data?.first_name}</DetailsTableRow>
                    <DetailsTableRow title="Last Name:">{user.profile_data?.last_name}</DetailsTableRow>
                    <DetailsTableRow title="Email:">{user.profile_data?.email}</DetailsTableRow>
                    <DetailsTableRow title="Created At:">{created_at}</DetailsTableRow>
                </DetailsTable>
            </DetailsHolder>
        </>
    );
}

export function UserPane({ value }: { value: UserId }) {
    return (
        <ContentPane>
            <ContentLoadingSuspense fallbackHeader={userHeader}>
                <UserDetails value={value} />
            </ContentLoadingSuspense>
        </ContentPane>
    );
}
