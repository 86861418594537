import { useState } from "react";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { VatText } from "../../components/VatText.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AdminModalButton } from "../AdminModalButton.tsx";
import { AInput } from "../form/Fields.tsx";
import { vatPercentageMutation } from "./accountsQuery.ts";
import type { GQAccountItem } from "./AccountsTable.tsx";

interface VatModalModalProps {
    item: GQAccountItem;
    value: number;
}

export function VatModalButton(props: VatModalModalProps) {
    return (
        <AdminModalButton label={`${props.value}%`} isDeleted={props.item.is_deleted}>
            <VatModalModalContent {...props} />
        </AdminModalButton>
    );
}

function VatModalModalContent({ item, value }: VatModalModalProps) {
    const modifyMutation = useStandardMutation(vatPercentageMutation);
    const [vat, setVat] = useState<string | undefined>(String(value));
    const { id } = item;

    async function onSubmit() {
        await modifyMutation.mutateAsync({
            body: {
                billing_account_id: Number(id),
                vat_percentage: vat ? Number(vat) : 0,
            },
        });
    }

    return (
        <WModalContent
            title={<VatText prefix="Set" suffix={`percentage for billing account ${id}`} />}
            label="Submit"
            modalAction={async () => await onSubmit()}
        >
            <WTextField autoFocus className="max-w-24rem" wide label={<VatText suffix="%" />} onChange={setVat}>
                <AInput value={vat} />
            </WTextField>
        </WModalContent>
    );
}
