import type { BillingAccountStatus } from "@warrenio/api-spec/spec.oats.gen";
import { useState } from "react";
import { entries } from "remeda";
import { Badge, type BadgeProps } from "../../components/Badge.tsx";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import FF from "../../components/forms/FormField.module.css";
import { RadioGroupDiv } from "../../components/forms/RadioGroupDiv.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { cn } from "../../utils/classNames.ts";
import { activateMutation, deactivateMutation, suspensionReasonMutation } from "./accountsQuery.ts";
import type { GQAccountItem } from "./AccountsTable.tsx";
import { BillingAccountBlock } from "./BillingAccountBlock.tsx";
import { SuspensionReasonTextBlock } from "./SuspensionReasonTextBlock.tsx";

interface StatusBadgeModalProps {
    item: GQAccountItem;
    value: BillingAccountStatus | undefined;
}

export function StatusBadge({ value }: { value: BillingAccountStatus | undefined }) {
    if (!value) return null;
    const { label, description, color } = statusDescriptions[value];
    return (
        <WTooltip text={description}>
            <Badge color={color}>{label}</Badge>
        </WTooltip>
    );
}

export function StatusBadgeButton(props: StatusBadgeModalProps) {
    const { value } = props;

    return (
        <WModal
            button={
                <EditableButton action={undefined}>
                    <StatusBadge value={value} />
                </EditableButton>
            }
        >
            <StatusBadgeModalContent {...props} />
        </WModal>
    );
}

function StatusBadgeModalContent({ item }: StatusBadgeModalProps) {
    const activateBaMutation = useStandardMutation(activateMutation);
    const deactivateBaMutation = useStandardMutation(deactivateMutation);
    const modifyMutation = useStandardMutation(suspensionReasonMutation);
    const { id } = item;
    const [reason, setReason] = useState<string>(item.suspend_reason ?? "");
    const [status, setStatus] = useState<BillingAccountStatus>(item.status as BillingAccountStatus);

    async function onSubmit() {
        if (status === "SUSPENDED") {
            await deactivateBaMutation.mutateAsync({
                body: {
                    billing_account_id: Number(id),
                },
            });

            if (reason) {
                await modifyMutation.mutateAsync({
                    body: {
                        billing_account_id: Number(id),
                        reason: reason ?? "",
                    },
                });
            }
        } else {
            await activateBaMutation.mutateAsync({
                body: {
                    billing_account_id: Number(id),
                },
            });
        }
    }

    return (
        <WModalContent title="Change billing account status" label="Change" modalAction={async () => await onSubmit()}>
            <div className="flex gap-3 items-center justify-between lightBlock mb-3">
                <MaskIcon className="jp-card-icon color-primary size-2.5rem" />
                <div className="flex-grow VStack gap-0.5">
                    <BillingAccountBlock value={item} />
                </div>
            </div>

            <RadioGroupDiv>
                {entries(statusDescriptions).map(([key, type]) => {
                    const isSelected = status === key;
                    const isPending = key === "PENDING_VALIDATION";

                    return (
                        <div
                            key={key}
                            onClick={
                                isPending
                                    ? undefined
                                    : (e) => {
                                          setStatus(key);
                                          e.stopPropagation();
                                      }
                            }
                            data-selected={isSelected ? true : undefined}
                            className={cn(FF.FormFieldIconRadio, FF.Size, FF.wide)}
                            style={{ padding: "0" }}
                        >
                            <div className={cn(FF.Header, "py-1")}>
                                <Badge reverse={isSelected} noDot color={type.color}>
                                    {type.label}
                                </Badge>
                            </div>

                            <div className="p-2 font-size-small">{type.description}</div>
                        </div>
                    );
                })}
            </RadioGroupDiv>

            {status === "SUSPENDED" && <SuspensionReasonTextBlock value={reason} onChange={setReason} />}
        </WModalContent>
    );
}

export const statusDescriptions: Record<
    BillingAccountStatus,
    {
        label: string;
        description: string;
        color: BadgeProps["color"];
    }
> = {
    PENDING_VALIDATION: {
        label: "Pending validation",
        description: "Billing account is waiting to be validated before it can be used.",
        color: "warning",
    },
    ACTIVE: {
        label: "Active",
        description: "Billing account is usable, user can add payment methods or top up the account.",
        color: "success",
    },
    SUSPENDED: {
        label: "Suspended",
        description:
            "Billing account is not usable, all related resources will be stopped, user will not be able to start them again. User can not create new billing accounts if they have a suspended account, even if the account is deleted.",
        color: "error",
    },
};
